import React, { useState } from 'react';
import Modal from './Modal';
import PortfolioItem from './PortfolioItem';

interface Project {
  title: string;
  description: string;
  details: string;
  images: string[];
}

const projects = [
    {
      title: "Employee Insurance Management System",
      description: "A web-based application designed to streamline the management of employee insurance policies.",
      details: "This system allows HR departments to manage employee insurance policies effectively. It provides features such as policy creation, claims management, and reporting tools to analyze insurance usage and costs.",
      images: [
        "https://via.placeholder.com/400x300?text=Employee+Insurance+1",
        "https://via.placeholder.com/400x300?text=Employee+Insurance+2"
      ],
    },
    {
      title: "Hospital Management System",
      description: "An integrated solution for managing hospital operations efficiently.",
      details: "The Hospital Management System is designed to automate the administrative, financial, and clinical aspects of a hospital. Key features include patient registration, appointment scheduling, billing, and electronic health records management.",
      images: [
        "https://via.placeholder.com/400x300?text=Hospital+Management+1",
        "https://via.placeholder.com/400x300?text=Hospital+Management+2"
      ],
    },
    {
      title: "School Management System",
      description: "A comprehensive platform for managing all aspects of school administration.",
      details: "This School Management System enables efficient management of student records, attendance tracking, grade reporting, and communication between teachers, students, and parents. The platform supports both web and mobile access.",
      images: [
        "https://via.placeholder.com/400x300?text=School+Management+1",
        "https://via.placeholder.com/400x300?text=School+Management+2"
      ],
    },
    {
      title: "News Application",
      description: "A responsive web application for reading and managing news articles.",
      details: "The News Application aggregates news from various sources, allowing users to filter by categories, save articles, and share them on social media. It features real-time updates and a clean, user-friendly interface.",
      images: [
        "https://via.placeholder.com/400x300?text=News+Application+1",
        "https://via.placeholder.com/400x300?text=News+Application+2"
      ],
    },
    {
      title: "Share Trading Platform",
      description: "An online platform for trading shares and managing investment portfolios.",
      details: "This Share Trading Platform provides users with tools to buy and sell shares, track their investments, and analyze market trends. It includes features such as real-time stock quotes, portfolio management, and transaction history.",
      images: [
        "https://via.placeholder.com/400x300?text=Share+Trading+1",
        "https://via.placeholder.com/400x300?text=Share+Trading+2"
      ],
    },
  ];

const Portfolio: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const openModal = (project: Project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <section className="py-16 bg-gray-100 text-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Portfolio</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            // <div
            //   key={index}
            //   className="rounded-lg shadow-lg overflow-hidden cursor-pointer"
            //   onClick={() => openModal(project)}
            // >
            //   <img
            //     src={project.images[0]} // Show the first image as the preview
            //     alt={project.title}
            //     className="w-full h-64 object-cover"
            //   />
            //   <div className="p-4">
            //     <h3 className="text-xl font-semibold">{project.title}</h3>
            //     <p className="mt-2">{project.description}</p>
            //   </div>
            // </div>
            <PortfolioItem key={index} project={project} />
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} project={selectedProject} />
    </section>
  );
};

export default Portfolio;
