import React from 'react';

interface Service {
  title: string;
  description: string;
}

const services: Service[] = [
  { title: "Web Development", description: "Modern, responsive websites using the latest technologies." },
  { title: "Mobile App Development", description: "Cross-platform mobile apps tailored to your needs." },
  { title: "UI/UX Design", description: "Visually appealing and user-friendly interface design." },
  { title: "E-commerce Solutions", description: "Fully-featured online stores with secure payment integrations." },
];

const Services: React.FC = () => {
  return (
    <section className="py-16 bg-white text-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div key={index} className="border border-gray-200 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
