import React, { useEffect, useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: {
    title: string;
    description: string;
    details: string;
    images: string[];
  } | null;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, project }) => {
  const [isScaling, setIsScaling] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setIsScaling(true);
      const timer = setTimeout(() => {
        setIsScaling(false);
      }, 300); // Match this duration with the CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen || !project) return null;

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div 
        className={`bg-white rounded-lg shadow-lg w-full h-auto max-h-[90vh] overflow-auto p-8 mt-16 mx-2 md:mx-4 lg:mx-6 transform transition-transform duration-300 ${isOpen ? 'scale-100' : 'scale-0'} ${isScaling ? 'scale-80' : ''}`}>
        
        <button 
          onClick={onClose} 
          className="mb-4 text-white bg-blue-600 hover:bg-blue-700 rounded px-4 py-2"
        >
          Back
        </button>
        <h2 className="text-3xl font-bold mb-4">{project.title}</h2>
        <p className="mb-4 text-lg">{project.description}</p>

        {/* Image Gallery */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          {project.images.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} className="w-full h-96 rounded-lg shadow" />
          ))}
        </div>

        {/* Detailed description */}
        <h3 className="text-xl font-semibold mb-2">Details</h3>
        <p className="text-gray-700">{project.details}</p>
      </div>
    </div>
  );
};

export default Modal;
