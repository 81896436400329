import React from 'react';

const Hero: React.FC = () => {
  return (
    <section className="bg-gray-900 text-white h-screen flex items-center justify-center">
      <div className="text-center px-4">
        <h1 className="text-5xl md:text-7xl font-bold">Modern Web & App Development</h1>
        <p className="mt-4 text-lg md:text-xl">Crafting advanced digital solutions for your business needs.</p>
        <button className="mt-8 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition">
          Get Started
        </button>
      </div>
    </section>
  );
};

export default Hero;
