import React from 'react';

interface Testimonial {
  name: string;
  feedback: string;
}

const testimonials: Testimonial[] = [
  { name: "John Doe", feedback: "This team is amazing! They delivered a top-notch website for my business." },
  { name: "Jane Smith", feedback: "Our mobile app is smooth and user-friendly thanks to their expertise." },
  { name: "Michael Lee", feedback: "Excellent work! I would highly recommend them for any project." },
];

const Testimonials: React.FC = () => {
  return (
    <section className="py-16 bg-white text-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">What Our Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="border border-gray-200 p-6 rounded-lg shadow-lg">
              <p className="italic mb-4">"{testimonial.feedback}"</p>
              <h3 className="text-lg font-semibold">- {testimonial.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
