import React, { useState } from 'react';
import Modal from './Modal';

const PortfolioItem: React.FC<{ project: { title: string; description: string; details: string; images: string[]; }; }> = ({ project }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isShrinking, setIsShrinking] = useState(false);

    const handleClick = () => {
        setIsShrinking(true);
        setTimeout(() => {
            setIsOpen(true);
            setIsShrinking(false);
        }, 300); // Match this duration with the CSS transition duration
    };

    return (
        <div>
            <div
                onClick={handleClick}
                className={`transition-transform duration-300 ${isShrinking ? 'scale-90' : 'scale-100'} cursor-pointer border p-4 rounded-lg shadow-md hover:shadow-lg`}
            >
                <img
                    src={project.images[0]} // Show the first image as the preview
                    alt={project.title}
                    className="w-full h-62 object-cover"

                />
                <div className="p-4">
                    <h3 className="text-xl font-semibold">{project.title}</h3>
                    <p className="mt-2">{project.description}</p>
                </div>
            </div>

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} project={project} />
        </div>
    );
};

export default PortfolioItem;
